import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import TextInput from "@/view/components/TextInput";
export default {
  name: "dashboard",
  data: function data() {
    return {
      drawer: null,
      items: [{
        title: "Home",
        icon: "mdi-view-dashboard"
      }, {
        title: "About",
        icon: "mdi-forum"
      }],
      tab: "contact-detail",
      detailsTab: null,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      recieverEmail: [],
      recieverEmails: ["********", "********", "********"],
      starterPlan: [{
        name: "Incident Management",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Self-service Portal",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Knowledge Base",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Incident Management",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Self-service Portal",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Knowledge Base",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Incident Management",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Self-service Portal",
        icon: "mdi-checkbox-multiple-marked-circle"
      }, {
        name: "Knowledge Base",
        icon: "mdi-checkbox-multiple-marked-circle"
      }],
      billingDetails: [{
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }, {
        date: "20/02/2022",
        invoice: "54652423542123",
        paymentMethod: "Master * 24",
        amount: "35.42",
        action: "Export to PDF"
      }]
    };
  },
  components: {
    TextInput: TextInput
  },
  methods: {
    remove: function remove(item) {
      var index = this.recieverEmail.indexOf(item);
      if (index >= 0) this.recieverEmail.splice(index, 1);
    }
  }
};